import * as React from 'react';
import { useState} from 'react'
import AvailabilityList from './AvailabilityList';
import useWindowSize from '../../utils/useWindowSize';
import { Card, Button, Alert, Form, FormGroup } from 'react-bootstrap'
import { useApprovedContext } from '../../contexts/ApprovedContext';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useAuth } from '../../contexts/AuthContext';

export default function Availability() {
  const windowSize = useWindowSize();
  const [error, setError] = useState("");
  const [ updateCount, setUpdateCount ] = useState(0);
  const { addAvailability } = useApprovedContext();
  const [selected, setSelected] = useState<Date | undefined>(undefined);
  const { currentUserInfo } = useAuth();

  const fromDate = new Date();
  const toDate = new Date();

  if (currentUserInfo?.isDriver) {
    toDate.setDate(toDate.getDate() + 60);
  } else {
    toDate.setDate(toDate.getDate() + 14);
  }


  async function handleNewAvailability(e : React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setError("")
    if (!selected) return;
    addAvailability(selected)
      .then(() => {
        setUpdateCount(updateCount + 1);
      })
//      .catch((error) => {setError(error)});
  }

  return (
    <Card>
      <Card.Body>
        <h2 className='text-center mb-4'>Add availability</h2>
        {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleNewAvailability}>
            <FormGroup id="date" className="d-flex justify-content-center">
              <DayPicker
                mode="single"
                required
                selected={selected}
                onSelect={setSelected}
                fromDate={fromDate}
                toDate={toDate}
                numberOfMonths={windowSize.width > 768 ? toDate.getMonth() - fromDate.getMonth() + 1 : 1}
              />
            </FormGroup>
            <Button className='w-100 mt-3' type="submit">Add availability</Button>
          </Form>
        <AvailabilityList setError={setError} updateCount={updateCount} setUpdateCount={setUpdateCount} />
      </Card.Body>
    </Card>
  )
}
