import * as React from 'react';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export interface RouteProps {
  children?: ReactNode
}

export default function MemberRoute({ children }: RouteProps) {
  const { currentUser, currentUserInfo } = useAuth()

  if (!currentUser) return <Navigate to="/login" />;

  if (currentUserInfo && currentUserInfo.isApproved) return <Navigate to="/dashboard" />

  return (
    <>
      {currentUserInfo && children}
    </>
  );
}
