import * as React from "react";
import { ColumnDef, ColumnHelper, Row, createColumnHelper } from "@tanstack/react-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Payment, PaymentStatus } from "../models/Payment";

export class PaymentsColumnBuilder {
  columnHelper: ColumnHelper<Payment>;
  columns: ColumnDef<Payment, any>[];

  constructor() {
    this.columnHelper = createColumnHelper<Payment>();
    this.columns = [];
  }

  addDate() {
    this.columns.push(
      this.columnHelper.accessor(
        row => row.datePaid,
        {
          header: "Date Paid",
          cell: info => info.getValue().toDateString()
        }
      )
    )
  }

  addPayee() {
    this.columns.push(
      this.columnHelper.accessor(
        row => `${row.paidBy.firstName} ${row.paidBy.lastName}`,
        {
          header: "Paid By",
          cell: info => <>
            <IndeterminateCheckbox
              {...{
                checked: info.row.getIsSelected(),
                disabled: !info.row.getCanSelect(),
                indeterminate: info.row.getIsSomeSelected(),
                onChange: info.row.getToggleSelectedHandler(),
              }}
            />
            {" " + info.getValue()}
          </>,
        }
      )
    )
  }

  addStatus() {
    const statusMSG = (status: PaymentStatus) => {
      switch (status) {
        case (PaymentStatus.created):
          return "Payment is awaiting admin approval";
        case (PaymentStatus.approved):
          return "Payment is approved";
      }
    }

    const statusIcon = (status: PaymentStatus) => {
      switch (status) {
        case (PaymentStatus.created):
          return "🟡";
        case (PaymentStatus.approved):
          return "🟢";
      }
    }

    this.columns.push(
      this.columnHelper.accessor(
        row => row.status,
        {
          header: "Status",
          cell: info =>
            <OverlayTrigger overlay={
              <Tooltip>
                {statusMSG(info.getValue())}
              </Tooltip>
            }>
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent"
                }}>
                {statusIcon(info.getValue())}
              </button>
            </OverlayTrigger>
        })
    )
  }

  addAmount() {
    this.columns.push(
      this.columnHelper.accessor(
        row => row.amount,
        {
          header: "Amount Paid",
          cell: info => `£${info.getValue() / 100}`
        }
      )
    )
  }

  // add() {
  //   this.columns.push(
  //     this.columnHelper.accessor(
  //       row => row.,
  //       {
  //         header: "",
  //         cell: info => info.getValue()
  //       }
  //     )
  //   )
  // }

  getColumns() {
    return this.columns
  }
}

type ExpanderType = {
  row: Row<Payment>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ExpandingButton(props: ExpanderType) {
  const { row } = props;
  return (
    (row.getCanExpand()) ? (
      <button
        {...{
          onClick: () =>
            row.toggleExpanded()
          ,
          style: {
            cursor: "pointer",
            border: "none",
            backgroundColor: "transparent"
          },
        }}
      >
        {row.getIsExpanded() ? "▽" : "▷"}
      </button>
    ) : (
      <span {...{
        style: { border: "none", paddingRight: "3rem" },
      }}
      >
        {""}
      </span>
    )
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}