import * as React from 'react'
import { Form, Card } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useApprovedContext } from '../../contexts/ApprovedContext';

export default function ProfileInformation() {
  const { currentUser, currentUserInfo, updateCurrentUserInfo } = useAuth();
  const { setDriver, setMaxPassengerCount, setPhoneNumber } = useApprovedContext();

  async function phoneNumberChangedHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const phoneNumber = event.currentTarget.value;
    if (phoneNumber === undefined) return;

    await setPhoneNumber(phoneNumber);
    updateCurrentUserInfo();
    console.log(currentUserInfo)
  }
  
  async function driverCheckedHandler() {
    setDriver(!currentUserInfo?.isDriver);
    updateCurrentUserInfo();
  }

  async function passengerCountChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const newCount = parseInt(event.currentTarget.value);
    if (newCount === undefined) return;

    await setMaxPassengerCount(newCount);
    updateCurrentUserInfo();
  }

  return (
    <Card style={{width: "21em"}}>
        <Card.Body>
          <h2 className='text-center mb-4'>Profile Information</h2>
          <Form>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                defaultValue={currentUser && currentUser.email ? currentUser.email : ""}
                disabled
              />
            </Form.Group>
            <Form.Group id="phone">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control
                type="tel"
                defaultValue={currentUserInfo && currentUserInfo.phoneNumber ? currentUserInfo.phoneNumber : ""}
                onChange={phoneNumberChangedHandler}
              />
            </Form.Group>
            <Form.Group id="driver" className='pt-3 row'>
              <Form.Check
                checked={currentUserInfo?.isDriver}
                onChange={driverCheckedHandler}
                className='col-1'
              />
              <Form.Label
                onClick={driverCheckedHandler}
                className='col-11'
              >
                Is Driver?
              </Form.Label>
            </Form.Group>
            <Form.Group id="driver" className='pt-3' hidden={!currentUserInfo?.isDriver}>
              <Form.Label>Passenger capacity (excl driver)</Form.Label>
              <Form.Control
                type='number'
                value={currentUserInfo?.passengerCount}
                min={0}
                onChange={passengerCountChanged}
              />
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
  )
}
