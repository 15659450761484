import * as React from 'react';
import { useState, useEffect } from 'react';
import { useApprovedContext } from '../../contexts/ApprovedContext';
import { UserInfo } from '../../contexts/ContextTypes';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AvailabilityColumnBuilder } from '../../table/AvailabilityColumnBuilder';
import BootstrapTable from '../../table/BootstrapTable';

type propsType = {
  setError: Function,
  updateCount: number,
  setUpdateCount: React.Dispatch<React.SetStateAction<number>>
}

export default function AvailabilityList(props : propsType) {
  const [userAvailability, setUserAvailability] = useState<Array<UserInfo>>([])
  const { getUserAvailability, removeAvailability } = useApprovedContext();
  const { updateCount, setUpdateCount } = props;

  async function removeAvailabilityHandler(availableDate: UserInfo) {
    if (!availableDate.dateRequested) return;
    await removeAvailability(availableDate.dateRequested);
    setUpdateCount(updateCount + 1);
  }   

  const columnBuilder = new AvailabilityColumnBuilder();
  columnBuilder.addAvailableDate();
  columnBuilder.addAvailabilityAddedOn();
  columnBuilder.addDeleteButton(removeAvailabilityHandler);

  const table = useReactTable({
    columns: columnBuilder.getColumns(),
    data: userAvailability,
    getCoreRowModel: getCoreRowModel(),
  });


  useEffect(() => {
    updateAvailability();

    async function updateAvailability() {
      setUserAvailability((await getUserAvailability()).filter(
        (availableDate) => availableDate.dateRequested! > new Date()
      ))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCount])

  return (
    <BootstrapTable
      table={table}
    ><></></BootstrapTable>
  )
}
