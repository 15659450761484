import { Accordion } from "react-bootstrap";
import * as React from "react";
import StaticWrapper from "../../components/StaticWrapper";

interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: "What does SUGC stand for?",
    answer:
      "Southampton University Gliding Club. We are a student run society with the purpose to introduce students to the thrill of soaring and allow advanced pilots to achieve new heights!",
  },
  {
    question: "What does SUGC do?",
    answer:
      "We go gliding, of course! Our members often start with no experience, and we go flying each weekend; mostly to enjoy ourselves, but also to learn about the sport. We have had members progress from pre-solo all the way to (nearly) instructor level in their time in the society. We also go on expeditions to other clubs and try to get involved with the nationwide gliding community wherever possible!",
  },
  {
    question: "What are expeditions?",
    answer:
      "We always try to attend the main gliding events for junior glider pilots around the country. UK Junior Gliding always organise three meets in the Winter (the Winter Series), as well as a National Competition (Junior Nationals) and some training events during the summer too (Summer Series). In addition, there is an Inter-Uni Gliding competition held each year. Aside from the Junior Nationals, these events are open to everyone. They are hugely fun, and allow us to experience new types of flying around the country. In addition, we also try to organise a trip specifically for SUGC each year.",
  },
  {
    question: "Do I have to be a student?",
    answer:
      "Yes, you must be enrolled as a current student at the University of Southampton as we are funded by the Student Union. Alumni and students of other universities should contact their own university or the British Gliding Association to find out if they have their own university society. Alternatively, you can join Shalbourne as a junior member and we’ll see you there!",
  },
  {
    question: "Are there any other limitations on who can join?",
    answer:
      "As a society, we make absolutely no discrimination on age, gender, race, subject studied or anything like that. The only limitations are related to safety or aviation laws and are rules which would apply to any gliding club.",
  },
  {
    question: "How many members are there?",
    answer:
      "We have to limit the number of members each year. Of course we want everyone to be a part of our society, but we have to find a balance between the number of people we introduce to flying and the frequency each member can fly to be able to progress. If we get full, we will make a waiting list and whenever we have free spaces to fly each weekend, we contact those people.",
  },
  {
    question: "When does SUGC go flying?",
    answer:
      "SUGC is able to fly whenever Shalbourne is open. This is typically one weekday (usually Wednesday) as well as both days at the weekend, as well as any Bank Holidays. We are there all day, from 8am until it goes dark, and try to fly as much as possible in that time.",
  },
];

export default function faq() {
  return (
    <StaticWrapper title="FAQ">
      <Accordion>
        {faqs.map((faq, index) => (
          <Accordion.Item eventKey={index.toString()}>
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>{faq.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </StaticWrapper>
  );
};
