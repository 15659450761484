import * as React from 'react';
import { useState } from 'react'
import { Card, Button, Alert, Form } from 'react-bootstrap'
import 'react-day-picker/dist/style.css';
import { useAuth } from '../../contexts/AuthContext';
import { useApprovedContext } from '../../contexts/ApprovedContext';
import { useDisplayedListsContext } from "../../contexts/DisplayedListsContext";
import BankDetailsView from '../BankDetailsView';

export default function MiscPayments() {
  const [error, setError] = useState("");
  const { bankDetails }= useDisplayedListsContext();
  const { updateCurrentUserInfo, currentUser } = useAuth();
  const { markMiscPaymentAsMade, getMiscPayment } = useApprovedContext();
  const [ ammount, setAmmount ] = useState<number>(42069);

  React.useEffect(() => {
    updateAmmount();
    async function updateAmmount() {
      if (!currentUser) throw new Error("not signed in");
      setAmmount(await getMiscPayment(currentUser.uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmission(e : React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setError("")
    if (!currentUser) return setError("Not logged in - please try signing in again");

    await markMiscPaymentAsMade(currentUser.uid);
    updateCurrentUserInfo();
  }

  return (
    <Card>
      <Card.Header>
        <h2 className='text-center mb-4'>Outstanding payments</h2>
        You have outstanding payments to be made totaling £{ammount / 100}. Please transfer them to the account listed below to continue:
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <BankDetailsView  bankDetails={bankDetails}/>
        <Form onSubmit={handleSubmission}>
          <Button className='w-100 mt-3' type="submit">Mark £{ammount / 100} as paid</Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
    
