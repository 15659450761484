import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
import { Alert, Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { DayPicker } from 'react-day-picker';
import { useAdminContext } from '../../contexts/AdminContext';
import { FlyingListInfo, UserInfo } from '../../contexts/ContextTypes';
import {ReactComponent as Add} from "../../imgs/add.svg"
import {ReactComponent as Minus} from "../../imgs/minus.svg"
import useWindowSize from '../../utils/useWindowSize';

export default function FlyingList() {
  const [selectedDate, setSelected] = useState< Date | undefined >(undefined);
  const [availableUsers, setAvailableUsers] = useState<Array<JSX.Element>>([]);
  const [flyingUsers, setFlyingUsers] = useState<Array<JSX.Element>>([]);
  const [finalMessage, setFinalMessage] = useState<string>("");
  const [updates, setUpdates] = useState(0);
  const [freeCarSeats, setFreeCarSeats] = useState(0);

  const leavingTimeRef = useRef<HTMLInputElement>(null);
  const notesRef = useRef<HTMLInputElement>(null);

  const { getFlyingListInfo, addUserToFlyingList, removeUserFromFlyingList, finalizeFlyingList, toggleUserDriverStatus } = useAdminContext();
  const windowSize = useWindowSize();

  // Finalize a flying list.
  function markListAsFinal(event : React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!selectedDate) return;

    if (!leavingTimeRef.current || !leavingTimeRef.current.valueAsDate) return;
    let hours = parseInt(leavingTimeRef.current.value.split(":")[0]);
    hours += parseFloat(leavingTimeRef.current.value.split(":")[1]) / 60;

    finalizeFlyingList(
      selectedDate,
      hours, 
      notesRef.current ? notesRef.current.value : ""
    ).then(() => {
      setUpdates(updates + 1);
    });
  }

  // Triggered when selected date is changed
  useEffect(() => {
    if (!selectedDate) return;
    handleDateSelection();

    // Add a user to the flying list for a given day.
    function addUserToList(userInfo: UserInfo) {
      if (!selectedDate) return;
      addUserToFlyingList(userInfo, selectedDate).then(() => {
        setUpdates(updates + 1);
      });
    }
  
    // Remove a user from the flying list for the selected day.
    function removeUserFromList(userInfo: UserInfo) {
      if (!selectedDate) return;
      removeUserFromFlyingList(userInfo, selectedDate).then(() => {
        setUpdates(updates + 1);
      });
    }

    function toggleDriverStatus(userInfo: UserInfo) {
      if (!selectedDate) return;
      toggleUserDriverStatus(userInfo, selectedDate).then(() => {
        setUpdates(updates + 1);
      });
    }
    
    // Create a request to the database for information about a given day.
    function handleDateSelection(): void {
      if (!selectedDate) return;
      getFlyingListInfo(selectedDate)
        .then(handleFlyingInfo)
        .catch(console.log);
    }

    // Use the data from the database to display info about the selected date
    function handleFlyingInfo(flyingInfo: FlyingListInfo) : void {
      setFinalMessage(flyingInfo.final ? "Flying list has been sent out, changes are no longer accepted." : "");

      setAvailableUsers(flyingInfo.availableUsers.map(
        (user) => availableUsersRow(
          user, 
          flyingInfo.currentFlyingList.some(u => u.uid === user.uid),
        )
      ));

      setFlyingUsers(flyingInfo.currentFlyingList.map(
        (user) => flyingListRow(
          user, 
          flyingInfo.final,
        )
      ));

      setFreeCarSeats(flyingInfo.currentFlyingList.reduce(
        (accumulator, currentUser) =>  
          accumulator + 
          ((currentUser.daysDriver && currentUser.passengerCount !== undefined) ? currentUser.passengerCount + 1 : 0)
        , 0
      ) - flyingInfo.currentFlyingList.length);
    }

    const availableUsersRow = (userInfo : UserInfo, buttonDisabled : boolean) => (
      <tr key={userInfo.uid}>
        <td className='text-center'>{((userInfo.isDriver) ? "🚗 " : "") + userInfo.firstName + " " + userInfo.lastName}</td>
        <td className='text-center'>{userInfo.dateCreated.toDateString()}</td>
        <td className='text-center'>
          <Button variant="success" className='d-inline-flex' disabled={buttonDisabled} onClick={() => {
            addUserToList(userInfo);
          }}>
            <Add className='bi bi-trash' height="15" width="15"/>
          </Button>
        </td>
      </tr>
    );

    const flyingListRow = (userInfo : UserInfo, buttonDisabled : boolean) => (
      <tr key={userInfo.uid}>
        <td className='text-center'>
          <Form>
            <Form.Switch
              checked={userInfo.daysDriver}
              onChange={() => {
                toggleDriverStatus(userInfo);
              }}
              disabled={!userInfo.isDriver || buttonDisabled}
            />
          </Form>
        </td>
        <td className='text-center'>{((userInfo.isDriver) ? "🚗 " : "") + userInfo.firstName + " " + userInfo.lastName}</td>
        <td className='text-center'>{userInfo.dateCreated.toDateString()}</td>
        <td className='text-center'>
          <Button variant="danger" className='d-inline-flex' disabled={buttonDisabled} onClick={() => {
            removeUserFromList(userInfo);
          }}>
            <Minus className='bi bi-trash' height="15" width="15"/>
          </Button>
        </td>
      </tr>
    );
  }, [selectedDate, getFlyingListInfo, addUserToFlyingList, removeUserFromFlyingList, toggleUserDriverStatus, updates, setUpdates])

  return (
      <Card>
        <Card.Header>
          <h2 className='text-center mb-4'>Generate Flying List</h2>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center">
          <Form>
            <DayPicker
              mode="single"
              required
              selected={selectedDate}
              onSelect={setSelected}
              fromDate={new Date()}
              showOutsideDays
              fixedWeeks
              numberOfMonths={windowSize.width > 768 ? 2 : 1}
            />
          </Form>
        </Card.Body>
        {selectedDate && /* Only show when a date is selected */
          <Card.Footer>
            <Row>
              <Col className='text-center mb-4'>
                <h2 >{selectedDate.toDateString()}</h2>
                { /* If the dates not finalized allow finalization */
                  (finalMessage) ? 
                    <Alert variant="success">{finalMessage}</Alert>
                   : 
                    <Form onSubmit={markListAsFinal}>
                      <Form.Group id="leavingTime">
                        <Form.Label>Leaving Time</Form.Label>
                        <Form.Control style={{textAlign: 'center'}} type="time" ref={leavingTimeRef} defaultValue="08:00" required />
                      </Form.Group>
                      <Form.Group id="notes">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control type="text" ref={notesRef} />
                      </Form.Group>
                      <Form.Group className='mt-1' id="freeCarSeats">
                        <Form.Label>Free Car Seats: {freeCarSeats}</Form.Label>
                      </Form.Group>
                      <Button className='mt-1' type='submit'>Send Out Flying List</Button>
                    </Form>
                }
              </Col>
            </Row>
            <Row>
              <Col md>
                <Card>
                  <Card.Header>
                    <h3 className='text-center mb-4'>Available Users</h3>
                  </Card.Header>
                  <Table>
                  <thead>
                    <tr>
                      <th className='text-center'>User</th>
                      <th className='text-center'>Availability added on</th>
                      <th className='text-center'>Add user to list</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableUsers}
                  </tbody>
                  </Table>
                </Card>
              </Col>
              <Col md>
                <Card>
                  <Card.Header>
                    <h3 className='text-center mb-4'>Current Flying List</h3>
                  </Card.Header>
                  <Table>
                  <thead>
                    <tr>
                      <th className='text-center'>Driver</th>
                      <th className='text-center'>User</th>
                      <th className='text-center'>Availability added on</th>
                      <th className='text-center'>Remove user from list</th>
                    </tr>
                  </thead>
                  <tbody>
                    {flyingUsers}
                  </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
            
            
          </Card.Footer>
        }
      </Card>
    )
}
