import * as React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {ReactComponent as Trash} from "../imgs/trash.svg"

export type removeButtonProps = {
  onClick: React.MouseEventHandler,
  disabled: boolean,
  hoverMessage?: string,
}

export default function RemoveButton(props: removeButtonProps) {
  let hoverMessage = props.hoverMessage;

  if (hoverMessage === undefined && props.disabled) 
    hoverMessage = "Flying list is finalized, please contact the driver if you wish to cancel"
  
  const delButton = ( 
    <Button
      variant='danger' 
      className='d-inline-flex p-2' 
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.disabled ? { pointerEvents: 'none' } : {}}
    >
      <Trash className='bi bi-trash'/>
    </Button>
  );

  return (
    (props.disabled) ?
      <OverlayTrigger 
        overlay={<Tooltip id="tooltip-disabled">{hoverMessage}</Tooltip>}
        placement='right'
        delay={{ show: 250, hide: 400 }}
      >
        <span className="d-inline-block">
          { delButton }
        </span>
      </OverlayTrigger> 
    :
      <>
        {delButton}
      </>
  )
}
