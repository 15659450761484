import * as React from 'react'
import { ReactNode } from 'react';
import { Card, Container } from "react-bootstrap";

export interface Props {
  children?: ReactNode,
  title: string
}

export default function StaticWrapper({children, title} : Props) {
  return (
    <Card className='border-0' style={{width: "100vw"}}>
      <Card.Title>
        <h1 className='ps-5'>
          {title}
        </h1>
      </Card.Title>
      <Card.Body>
        <Container className="mb-4">
          { children }
        </Container>
      </Card.Body>
    </Card>
  )
}