import * as React from "react";
import { Row, Col, Card } from "react-bootstrap";
import StaticWrapper from "../../components/StaticWrapper";

export default function WhatIsGliding() {
  return (
    <StaticWrapper title="What is Gliding">
      <Row className="mt-3 px-3">
        <Col>
          <p>
            Gliding involves flying unpowered aircraft using the same naturally
            occurring currents of air that birds use to fly. Using these
            invisible currents of air, you can soar to great heights and travel
            great distances around the country at average speeds of over 100
            miles per hour.
          </p>
          <p>
            Gliders are similar to normal aircraft, just without an engine. We
            exploit rising air in the atmosphere to stay airborne – at least
            that’s the idea! People glide for many reasons. Some use it as a
            stepping stone to a full pilots licence, but the real joy of gliding
            is when it’s treated as a sport.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={4}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Thermals</Card.Title>
              <Card.Text>
                These are bubbles of rising air caused by the sun unevenly
                heating the ground (think of a lava lamp!). This the most common
                kind of lift we see.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Ridge Lift</Card.Title>
              <Card.Text>
                This is caused by air being pushed upwards when it blows towards
                a slope. We fly along the ridge to exploit this.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Mountain Wave</Card.Title>
              <Card.Text>
                These are currents of air that rise over the top of mountains
                and then sink back down and rebound up, this can continue for
                hundreds of miles.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3 px-1">
        <Col md={6} className="px-3">
          <iframe
            title="gliding-video1"
            className="ratio ratio-16x9"
            height="300"
            src="https://www.youtube-nocookie.com/embed/5uzJWxm54Uo"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Col>
        <Col md={6} className="px-3">
          <iframe
            title="gliding-video2"
            className="ratio ratio-16x9"
            height="300"
            src="https://www.youtube-nocookie.com/embed/UHr2Hxk-E8Q"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Col>
      </Row>
    </StaticWrapper>
  );
};
