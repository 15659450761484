import * as React from "react";
import { Row, Col } from "react-bootstrap";
import meet from "../../imgs/meet.webp";
import setup from "../../imgs/setup.webp";
import fly from "../../imgs/fly.webp";
import packup from "../../imgs/packup.webp";
import fees from "../../imgs/pay-fees.webp";
import StaticWrapper from "../../components/StaticWrapper";

export default function TypicalDay() {
  return (
    <StaticWrapper title="A Typical Day">
      <Row>
        <Col md={4}>
          <img
            className="img-fluid rounded mb-3 mb-md-0"
            src={meet}
            alt="SUSU shop"
          />
        </Col>
        <Col md={8}>
          <h3>Meet up</h3>
          <p>
            On the morning of your flying day, we meet outside{" "}
            <a href="https://data.southampton.ac.uk/building/57.html">
              The Shop on Redbrick, Highfield Campus
            </a>{" "}
            at 0800. Please make sure you are there on time, otherwise your
            driver might have to leave without you! Check out the{" "}
            <a href="/faq">FAQ</a> for what you should bring along with you.
          </p>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col md={4}>
          <img
            className="img-fluid rounded mb-3 mb-md-0"
            src={setup}
            alt="Shalbourne hangar"
          />
        </Col>
        <Col md={8}>
          <h3>Setup the airfield</h3>
          <p>
            After an approximately hour long drive up to the airfield, we help
            set up for the day of flying. If you're new to gliding don't worry,
            you won't be asked to do anything you don't understand yet! There
            are three main things that need setting up: the winch, the launch
            point and the gliders themselves.
          </p>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col md={4}>
          <img
            className="img-fluid rounded mb-3 mb-md-0"
            src={fly}
            alt="Flying in a glider"
          />
        </Col>
        <Col md={8}>
          <h3>Fly!</h3>
          <p>This bit shouldn't need any introduction!</p>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col md={4}>
          <img
            className="img-fluid rounded mb-3 mb-md-0"
            src={packup}
            alt="Packing up the hangar"
          />
        </Col>
        <Col md={8}>
          <h3>Pack back up</h3>
          <p>
            After flying, we need to put everything back as it was before we
            came and secure the gliders, so they are still there next time we
            come! We make our way back after this is done.
          </p>
          <p>
            <b>
              We ask that you contribute £5 towards the driver's costs, the club
              doesn't reimburse them for driving!
            </b>
          </p>
        </Col>
      </Row>

      <hr />

      <Row className="mb-5">
        <Col md={4}>
          <img
            className="img-fluid rounded mb-3 mb-md-0"
            src={fees}
            alt="Glider with sunset"
          />
        </Col>
        <Col md={8}>
          <h3>Pay your flying fees</h3>
          <p>
            As much as we would like to make your flying free, we can't :( .
            Soon after your flying day, you should see a corresponding fees
            entry on your profile page. Please pay your fees in a timely manner!
          </p>
          <p>
            Don't forget to make sure you've added some availability so you can
            go flying again!
          </p>
        </Col>
      </Row>
    </StaticWrapper>
  );
}
