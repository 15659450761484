import * as React from 'react';
import { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const ALLOWED_EMAIL_DOMAINS = ["soton.ac.uk", "southampton.ac.uk"]

export default function Signup() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const dobRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);
  const { signup } = useAuth()
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  type PasswordsMatch = () => boolean | null;
  const passwordsMatch: PasswordsMatch = () => ( passwordRef.current 
      && passwordConfirmRef.current 
      && passwordRef.current.value === passwordConfirmRef.current.value
    );

  async function handleSubmit(e : React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();

    if (!emailRef.current) return;
    if (!ALLOWED_EMAIL_DOMAINS.includes(emailRef.current.value.split("@")[1])) {
      return setError("Email must be a valid University of Southampton email address");
    }

    if (!passwordsMatch()) {
      return setError("Passwords do not match");
    }

    if (!dobRef.current) return;
    if (dobRef.current.valueAsDate === null) return;

    try {
      setError("");
      setLoading(true)
      if (emailRef.current && lastNameRef.current && firstNameRef.current && passwordRef.current)
        await signup(
          emailRef.current.value,
          passwordRef.current.value,
          firstNameRef.current.value,
          lastNameRef.current.value,
          dobRef.current.valueAsDate
        );
      navigate("/dashboard")
    } catch (ex) {
      setError("Failed to create account");
      console.log(ex);
    }

    setLoading(false);
  }

  return (
    <div>
      <Card style={{width: "21em"}}>
        <Card.Body>
          <h2 className='text-center mb-4'>Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="name" ref={firstNameRef} required />
            </Form.Group>
            <Form.Group id="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="name" ref={lastNameRef} required />
            </Form.Group>
            <Form.Group id="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control type="date" ref={dobRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button disabled={loading} className='w-100 mt-3' type="submit">Sign Up</Button>
          </Form>

        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log in</Link>
      </div>
    </div>
  )
}
