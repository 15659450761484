import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { auth, db } from "../firebase";
import { Timestamp, doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { User,
  updatePassword,
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail } from "firebase/auth";
import { AuthContextModel } from './AuthContextInterface';
import { CurrentUserInfoType, ProviderProps, UserState } from './ContextTypes';
import { timestampToDate } from './Utils';

const AuthContext = React.createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth() : AuthContextModel {
  return useContext(AuthContext);
}

export function AuthProvider({ children } : ProviderProps) : JSX.Element {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState<CurrentUserInfoType>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    updateCurrentUserInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  async function updateCurrentUserInfo() {
    if (!currentUser) return setCurrentUserInfo(undefined);

    const userSnap = await getDoc(doc(db, "users", currentUser.uid));
    if (!userSnap.exists()) return setCurrentUserInfo(undefined);

    setCurrentUserInfo({
      uid: currentUser.uid,
      isApproved: Boolean(userSnap.get("approved")),
      isAdmin: Boolean(userSnap.get("admin")),
      isDriver: Boolean(userSnap.get("driver")),
      passengerCount: userSnap.get("passengerCount"),
      firstName: userSnap.get("firstName"),
      lastName: userSnap.get("lastName"),
      userState: userSnap.get("userState"),
      formSigned: timestampToDate(userSnap.get("formSigned")),
      dob: timestampToDate(userSnap.get("dob")),
      fcmTokens: userSnap.get("fcmTokens"),
      phoneNumber: userSnap.get("phoneNumber"),
    });
  }

  async function signup(email: string, password: string,  firstName: string, lastName: string, dob: Date) {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    
    await setUserDetails(
      firstName,
      lastName,
      dob,
      user.user 
    )
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateUserPassword(password: string) {
    if (!currentUser) throw new Error("Not logged in");
    return updatePassword(currentUser, password);
  }

  async function setUserDetails(firstName: string, lastName: string, dob: Date, user: User) {
    await setDoc(doc(db, `users/${user.uid}`), {
      firstName: firstName,
      lastName: lastName,
      dob: Timestamp.fromDate(dob),
    },
    {
      merge: true,
    })

    await updateProfile(user, {
      displayName: firstName
    });
  }
  
  async function markDetailsAsSet(user: User) {
    const payment = await getDoc(doc(db, "miscInvoices", user.uid))
    if (!payment.exists()) return await
      updateDoc(doc(
        db,
        "users",
        user.uid
      ), {
        userState: UserState.approved
      });
    else return await
      updateDoc(doc(
        db,
        "users",
        user.uid
      ), {
        userState: UserState.newOutstandingPayment
      });
  }
  
  async function markOnBoardingAsUpdated() {
    if (!currentUser) return;
    await updateDoc(doc(
      db,
      "users",
      currentUser.uid
    ), {
      formSigned: serverTimestamp(),
    });
    await updateCurrentUserInfo();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    })

    return unsubscribe;
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updatePassword: updateUserPassword,
    currentUserInfo,
    updateCurrentUserInfo,
    setUserDetails,
    markDetailsAsSet,
    markOnBoardingAsUpdated,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
