import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import StaticWrapper from "../../components/StaticWrapper";

export default function Pricing() {
  // TODO: Dynamic pricing updates from DB
  return (
    <StaticWrapper title="Pricing">
      <Row className="justify-content-md-center">
        <Col md={6} lg={8}>
          <Card className="mb-4">
            <Card.Header as="h3" className="text-center">
              {"Juniors (<= 26)"}
            </Card.Header>
            <Card.Body>
              <Card.Title className="text-center">£9.00 per launch</Card.Title>
              <Card.Text className="text-center">32p per minute</Card.Text>
              <ul className="list-unstyled">
                <li>First 15mins is free!</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={8}>
          <Card className="mb-4">
            <Card.Header as="h3" className="text-center">
              {"Adult (> 26)"}
            </Card.Header>
            <Card.Body>
              <Card.Title className="text-center">£9.00 per launch</Card.Title>
              <Card.Text className="text-center">32p per minute</Card.Text>
              <ul className="list-unstyled">
                <li>First 15mins is free!</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StaticWrapper>
  );
};
