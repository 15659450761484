import { QueryDocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";

export class FlyingRate {
    rateName: string;

    actualLaunchCost: number;
    actualMinuteCost: number;
    actualTLFCost: number;
    subsidisedLaunchCost: number;
    subsidisedMinuteCost: number;
    subsidisedTLFCost: number;
    
    subsidisedAmountOfMinutes: number;
    dailyRentalCost: number;
    maxAge: number;

    periodStart: Date;
    periodEnd: Date;

  constructor(
    rateName: string,

    actualLaunchCost: number,
    actualMinuteCost: number,
    actualTLFCost: number,
    subsidisedLaunchCost: number,
    subsidisedMinuteCost: number,
    subsidisedTLFCost: number,
    
    subsidisedAmountOfMinutes: number,
    dailyRentalCost: number,
    maxAge: number,

    periodStart: Date,
    periodEnd: Date,
  ) {
    this.rateName = rateName;

    this.actualLaunchCost = actualLaunchCost
    this.actualMinuteCost = actualMinuteCost
    this.actualTLFCost = actualTLFCost
    this.subsidisedLaunchCost = subsidisedLaunchCost
    this.subsidisedMinuteCost = subsidisedMinuteCost
    this.subsidisedTLFCost = subsidisedTLFCost
    
    this.subsidisedAmountOfMinutes = subsidisedAmountOfMinutes
    this.dailyRentalCost = dailyRentalCost
    this.maxAge = maxAge

    this.periodStart = periodStart;
    this.periodEnd = periodEnd;
  }
}

export var flyingRateConverter = {
  toFirestore: function(flyingRate: FlyingRate) {
      return {
        rateName: flyingRate.rateName,
        actualLaunchCost: flyingRate.actualLaunchCost,
        actualMinuteCost: flyingRate.actualMinuteCost,
        actualTLFCost: flyingRate.actualTLFCost,
        subsidisedLaunchCost: flyingRate.subsidisedLaunchCost,
        subsidisedMinuteCost: flyingRate.subsidisedMinuteCost,
        subsidisedTLFCost: flyingRate.subsidisedTLFCost,
        
        subsidisedAmountOfMinutes: flyingRate.subsidisedAmountOfMinutes,
        dailyRentalCost: flyingRate.dailyRentalCost,
        maxAge: flyingRate.maxAge,

        periodStart: flyingRate.periodStart,
        periodEnd: flyingRate.periodEnd,
      };
  },
  fromFirestore: function(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions) {
      return new FlyingRate(
        snapshot.get("rateName"),
        snapshot.get("actualLaunchCost"), 
        snapshot.get("actualMinuteCost"),
        snapshot.get("actualTLFCost"),
        snapshot.get("subsidisedLaunchCost"),
        snapshot.get("subsidisedMinuteCost"),
        snapshot.get("subsidisedTLFCost"),
       
        snapshot.get("subsidisedAmountOfMinutes"),
        snapshot.get("dailyRentalCost"),
        snapshot.get("maxAge"),
        
        new Timestamp(snapshot.get("periodStart").seconds, snapshot.get("periodStart").nanoseconds).toDate(),
        new Timestamp(snapshot.get("periodEnd").seconds, snapshot.get("periodEnd").nanoseconds).toDate(),
      );
  }
};