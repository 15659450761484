import * as React from 'react';
import { Row, Col, Modal, Toast } from 'react-bootstrap'
import Availability from '../components/dashboard/Availability';
import Flights from '../components/dashboard/Flights';
import Payments from '../components/dashboard/Payments';
import NewUserSetup from '../components/dashboard/NewUserSetup';
import { getFCMToken, onForegroundMessageListener } from '../firebase';
import { MessagePayload } from 'firebase/messaging';
import { useApprovedContext } from '../contexts/ApprovedContext';
import { useAuth } from '../contexts/AuthContext';
import { UserState } from '../contexts/ContextTypes';
import MiscPayments from '../components/dashboard/MiscPayments';

export default function Dashboard() {
  const [showModal, setShowModal] = React.useState(true);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notification, setNotification] = React.useState({ title: "", body: "" });
  const [token, setToken] = React.useState("");
  const { addUserFcmToken } = useApprovedContext();
  const { currentUserInfo } = useAuth();

  React.useEffect(() => {
    getFCMToken(setToken);
  }, []);

  React.useEffect(() => {
    if (token) {
      // Ensure this device FCM token is added to the user's fcmTokens array
      // This is safe to call multiple times, the token will only be added once
      addUserFcmToken(token);

      // We can now register the service worker to receive background messages
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js", {
          scope: "/",
        })
        .then((registration) => {
          console.log(
            "Service worker registration successful, scope is:",
            registration.scope
          );
        })
        .catch((err) => {
          console.log("Service worker registration failed, error:", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  onForegroundMessageListener()
    .then((payload): void => {
      const msgPayload = payload! as MessagePayload;
      setShowNotification(true);
      setNotification({
        title: msgPayload.notification!.title!,
        body: msgPayload.notification!.body!,
      });
      console.log(payload);
    })
    .catch((err: any) => console.log("failed: ", err));
  if (!currentUserInfo) throw new Error("Bruh");
  
  function formSignedInLastYear() {
    if (!currentUserInfo) return false;
    if (!currentUserInfo.formSigned) return false;
    if (
        ((new Date()).valueOf() - currentUserInfo.formSigned.valueOf())
        /
        (1000 * 3600 * 24 * 365) > 1 
      ) return false;
    return true;
  }

  return (
    <>
      {token && (
        <Toast
          onClose={() => setShowNotification(false)}
          show={showNotification}
          delay={5000}
          autohide
          animation
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            minWidth: 200,
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">{notification.title}</strong>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      )}
      {!token && (
        <Modal show={showModal} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Flying List Notifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Looks like this device isn't signed up receive flying list
              notifications
            </p>
            <p>
              <b>This means you might miss opportunities to fly</b>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Not Now
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                getFCMToken(setToken);
                setShowModal(false);
                setNotification({
                  title: "Notifications Enabled",
                  body: "You will now receive flying list notifications on this device",
                });
                setShowNotification(true);
              }}
            >
              Allow Notifications
            </button>
          </Modal.Footer>
        </Modal>
      )}
      { currentUserInfo.userState === UserState.new &&
        <NewUserSetup></NewUserSetup>
      }
      { currentUserInfo.userState === UserState.newOutstandingPayment && 
        <MiscPayments />
      }
      {currentUserInfo.userState > UserState.unapproved &&
        <>
          {!formSignedInLastYear() &&
            <div>
              <h4 className='p-4'>Before you can fly you must ensure you have read the <a href="https://1drv.ms/b/s!AmF7UW15c8YUhIEmxJgufWTbGVbBPA?e=FzlWUE" target="_blank" rel="noreferrer">Mandatory Safety and Medical Notes</a>. 
              You must then fill in this <a 
                href='https://forms.gle/swWC6jWY1LTFZFm58' 
              >Form</a>
              .</h4>
            </div>
          }
          {formSignedInLastYear() &&
            <Row>
              <Col md>
                <Availability />
              </Col>
              <Col md>
                <Flights />
              </Col>
              <Col md>
                <Payments />
              </Col>
            </Row>
          }
        </>
      }
    </>
  );
}
