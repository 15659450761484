import {Timestamp, FieldValue} from "firebase/firestore"

export interface ProviderProps {
  children?: React.ReactNode
}

export type SelectableUser = {
  uid: string,
  firstName: string,
  lastName: string,
  age: number,
}

export enum UserState {
  new,
  newOutstandingPayment,
  unapproved,
  approved,
  admin,
}
export type CurrentUserInfoType = {
  uid : string,
  isApproved: boolean,
  isAdmin: boolean,
  isDriver: boolean,
  passengerCount?: number,
  firstName: string,
  lastName: string,
  formSigned?: Date,
  dob?: Date,
  userState: UserState,
  fcmTokens?: Array<string>,
  phoneNumber?: string,
}

export type BankDetailsType = {
  fullName: string,
  sortCode: string,
  accountNumber: string,
  reference?: string,
}

export type AvailabilityData = {
  dateRequested: Timestamp,
  dateCreated: FieldValue,
  userFCMTokens: Array<string>,
  isDriver?: boolean,
  passengerCount?: number,
  firstName?: string,
  lastName?: string,
}

export type UserInfo = {
  uid: string,
  firstName: string,
  lastName: string,
  email?: string,
  dateCreated: Date,
  dateRequested?: Date
  dob: Date,
  isDriver?: boolean,
  daysDriver?: boolean,
  passengerCount?: number,
  final?: boolean,
}

export type FlyingListInfo = {
  availableUsers: Array<UserInfo>,
  currentFlyingList: Array<UserInfo>,
  final: boolean
}

export type Airfield = {
  name: string,
  winchLaunchCost: number,
  winchTLFCost: number,
  winchRLFCost: number,
}
