import * as React from 'react'

export default function PageNotFound() {
  return (
    <div className='text-center'>
      <h1>404 - Page not Found</h1>
      <h2>This is not the page your looking for</h2>
    </div>
  )
}
