import { Row, Col, Image, Card } from "react-bootstrap";
import * as React from "react";
import about from "../../imgs/about-hero.webp";
import experience from "../../imgs/experience.webp";
import train from "../../imgs/train.webp";
import achieve from "../../imgs/achieve.webp";
import compete from "../../imgs/compete.webp";
import StaticWrapper from "../../components/StaticWrapper";

export default function About() {
  return (
    <StaticWrapper title="About Us">
      <Row>
        <Col className="text-center">
          <Image
            fluid
            rounded
            className="mb-4 center-img"
            src={about}
            alt="Long Mynd sunset"
          />
        </Col>
      </Row>
      <Row className="px-3">
        <Col>
          <p>
            SUGC is the Southampton University Gliding Club. We are here to
            offer the best quality gliding at the best price, while having a
            great day! The Club re-established in 2015 and over 1000 tremendous
            flights have occurred since! SUGC flies at{" "}
            <a href="http://shalbournegliding.co.uk/">
              Shalbourne Gliding Club?
            </a>
            . Gliding is suitable for anyone from any background from any
            degree! No knowledge needed! We fly as often as we can, we aim for 3
            times a week, along with some fantastic trips with other Uni’s and
            independent trips!
          </p>
          <h3>Who is SUGC for?</h3>
          <p>
            SUGC is a club that can give you a unique experience unlike any
            other. We are a friendly club that welcomes everyone. Beginners are
            helped to achieve their ambitions while experienced pilots will not
            be held back! Once solo, some pilots like to fly cross country, some
            like to fly upside down, and some just like to potter around the sky
            for a bit of peace and quiet. Whatever your goals, this is the place
            to be!
          </p>
          <p>
            We allow any Southampton University students to join, regardless of
            course, age, or anything else. Of course, there are some
            restrictions beyond our control, but if you are over 14 years old
            and have no medical conditions which prevent you getting a driving
            license, you can fly solo! Many disabilities can also be
            accommodated in gliding. Check out our <a href="/faq">FAQ</a> for
            more info.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={3} sm={6} className="portfolio-item">
          <Card className="h-100">
            <Card.Img
              variant="top"
              src={experience}
              alt="Amazed by the flight"
            />
            <Card.Body>
              <Card.Title>Experience</Card.Title>
              <Card.Text>
                Never even heard of gliding? Come and do a trial flight with us
                and see what all the fuss it about!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} sm={6} className="portfolio-item">
          <Card className="h-100">
            <Card.Img variant="top" src={train} alt="Landing glider" />
            <Card.Body>
              <Card.Title>Train</Card.Title>
              <Card.Text>
                Most of our members join with zero experience, and start by
                training for their first solo flight!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} sm={6} className="portfolio-item">
          <Card className="h-100">
            <Card.Img
              variant="top"
              src={achieve}
              alt="Long Mynd busy ridge soaring"
            />
            <Card.Body>
              <Card.Title>Achieve</Card.Title>
              <Card.Text>
                Solo is just the start of the journey. Achieve badges or set
                your own goals.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} sm={6} className="portfolio-item">
          <Card className="h-100">
            <Card.Img variant="top" src={compete} alt="Launch grid" />
            <Card.Body>
              <Card.Title>Compete</Card.Title>
              <Card.Text>
                All of our members have the opportunity to compete at
                inter-university, regional and national competitions.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StaticWrapper>
  );
};
