import { QueryDocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";
import { SelectableUser } from "../contexts/ContextTypes";
import { Flight } from "./Flight";
import { CostCalculator } from "./CostCalculator";

//TODO: Rename to DayStatus
export enum Status {
  Unpublished,
  Unpaid,
  ClaimedPaid,
  Paid,
}

export class UserFlyingDay {
  date: Date;
  user: SelectableUser;
  totalMins: number;
  launches: number;

  flights: Array<Flight>;
 
  cost: number;

  status: Status;

  constructor (
    date: Date,
    user: SelectableUser,
    totalMins: number,
    launches: number,
    status: Status,
    cost?: number,
  ) {
    this.date = date;
    this.user = user;
    this.totalMins = totalMins;
    this.launches = launches;
    this.status = status

    this.flights = [];
    this.cost = (status === Status.Unpublished || !cost) ? 0 : cost;
  }
  
  getDayReference = () => 
    Timestamp.fromDate(this.date).valueOf() + this.user.uid

  equals = (date: Date, uid: string) => 
    this.date.valueOf() === date.valueOf() &&
    this.user.uid === uid

  setFlights(flights: Array<Flight>) {
    this.flights = flights;
    this.updateAggregation();
  }

  addFlight(flight: Flight) {
    this.flights.push(flight);
    this.updateAggregation();
  }

  updateAggregation() {    
    this.totalMins = this.flights.reduce(
      (total, flight) => total + flight.length, 
      0
    );

    this.launches = this.flights.length;
    if (this.status !== Status.Unpublished) return;

    this.cost = CostCalculator.calculateCost(this.flights);
  }
}

// Firestore data converter
export var userFlyingDayConverter = {
  toFirestore: function(flyingDay: UserFlyingDay) {
      return {
        id: flyingDay.getDayReference(),
        date: flyingDay.date,
        user: flyingDay.user,
        totalMins: flyingDay.totalMins,
        launches: flyingDay.launches,
        status: flyingDay.status,
        cost: flyingDay.cost,
      };
  },
  fromFirestore: function(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions) {
      return new UserFlyingDay(
        new Timestamp(snapshot.get("date").seconds, snapshot.get("date").nanoseconds).toDate(),
        snapshot.get("user"),
        snapshot.get("totalMins"),
        snapshot.get("launches"),
        snapshot.get("status"),
        snapshot.get("cost"),
      );
  }
};