import * as React from 'react';
import {  Col, Row } from "react-bootstrap";
import FlyingList from '../components/admin/FlyingList';
import UnapprovedUserList from '../components/admin/UnapprovedUserList';
import FlightInput from '../components/admin/FlightInput';
import UnpublishedFlights from '../components/admin/UnpublishedFlights';
import PaymentApproval from '../components/admin/PaymentApproval';

export default function Admin() {
  return (
    <Row>
      <Col xl>
        <FlyingList />
      </Col>
      <Col lg>
        <UnapprovedUserList />
      </Col>
      <Col sm>
        <FlightInput />
      </Col>
      <Col lg className='d-none d-md-block'>
        <UnpublishedFlights />
      </Col>
      <Col lg className='d-none d-md-block'>
        <PaymentApproval />
      </Col>
    </Row>
  )
}
