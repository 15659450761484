import * as React from "react";
import { Pagination, Table } from "react-bootstrap";
import { Table as TanTable, flexRender} from "@tanstack/react-table";
import useWindowSize from "../utils/useWindowSize";

type propsType = {
  table: TanTable<any>,
  children: React.ReactNode,
}

export default function BootstrapTable(props: propsType) {
  const {width} = useWindowSize();

  return (
    <div className="row">
      <Table className='col-sm-12'>
        <thead>
          <tr>
            {
              props.table.getHeaderGroups().map((headerGroup) => (
                headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      ) as React.ReactNode}
                  </th>
                ))
              ))
            }
          </tr>
        </thead>
        <tbody>
          {props.table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext()) as React.ReactNode}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className={width < 576 ? 'col-8' : 'col-4'}>
        <Pagination.First onClick={() => props.table.setPageIndex(0)} />
        <Pagination.Prev onClick={() => props.table.previousPage()} disabled={!props.table.getCanPreviousPage()} />
        <Pagination.Item className="text-nowrap" active>{props.table.getState().pagination.pageIndex + 1} of {props.table.getPageCount()}</Pagination.Item>
        <Pagination.Next onClick={() => props.table.nextPage()} disabled={!props.table.getCanNextPage()} />
        <Pagination.Last onClick={() => props.table.setPageIndex(props.table.getPageCount() - 1)} />
      </Pagination>
      <div className={width < 576 ? 'row' : 'col-8'}>
        {props.children}
      </div>
    </div>
  )
}
