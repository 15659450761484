import { QueryDocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";
import { SelectableUser } from "../contexts/ContextTypes";

export enum PaymentStatus {
  created,
  approved,
}

export class Payment {
  id: string;
  amount: number;
  datePaid: Date;
  paidBy: SelectableUser;
  status: PaymentStatus;
  paysOff: Array<string>;
  payeeNotes?: string;
  approvedBy?: SelectableUser;
  dateApproved?: Date;
  approverNotes?: string;

  constructor (
    id: string,
    amount: number,
    datePaid: Date,
    paidBy: SelectableUser,
    status: PaymentStatus,
    paysOff: Array<string>,
    payeeNotes?: string,
    approvedBy?: SelectableUser,
    dateApproved?: Date,
    approverNotes?: string,
  ) {
    this.id = id;
    this.amount = amount;
    this.datePaid = datePaid;
    this.paidBy = paidBy;
    this.status = status;
    this.paysOff = paysOff;
    this.payeeNotes = payeeNotes;
    this.approvedBy = approvedBy;
    this.dateApproved = dateApproved;
    this.approverNotes = approverNotes;
  }
}

type paymentFirestoreType = {
  amount: number,
  datePaid: Date,
  paidBy: SelectableUser,
  status: PaymentStatus,
  paysOff: Array<string>,
  payeeNotes?: string,
  approvedBy?: SelectableUser,
  dateApproved?: Date,
  approverNotes?: string,
}
const dateFromTimestamp = (date?: Timestamp) => 
  date ? new Timestamp(date.seconds, date.nanoseconds).toDate() : undefined;

// Firestore data converter
export const paymentsConverter = {
  toFirestore: (payment: Payment) => {
    let data: paymentFirestoreType = {
      amount: payment.amount,
      datePaid: payment.datePaid,
      paidBy: payment.paidBy,
      status: payment.status,
      paysOff: payment.paysOff,
    }

    if (payment.payeeNotes)
      data.payeeNotes = payment.payeeNotes;

    if (payment.approvedBy)
      data.approvedBy = payment.approvedBy;

    if (payment.dateApproved) 
      data.dateApproved = payment.dateApproved;

    if (payment.approverNotes)
      data.approverNotes = payment.approverNotes
    
    return data;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) => {

    const datePaid = dateFromTimestamp(snapshot.get("datePaid"));
    if (!datePaid) throw new Error("This payment was never made...?")
    
    let dateApproved = dateFromTimestamp(snapshot.get("dateApproved"));
    if (!dateApproved)
      dateApproved = undefined;


    return new Payment(
      snapshot.id,
      snapshot.get("amount"),
      datePaid,
      snapshot.get("paidBy"),
      snapshot.get("status"),
      snapshot.get("paysOff"),
      snapshot.get("payeeNotes"),
      snapshot.get("approvedBy"),
      dateApproved,
      snapshot.get("approverNotes"),
    );
  }
};