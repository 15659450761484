import * as React from "react";
import { Container } from "react-bootstrap";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import { AuthProvider } from "./contexts/AuthContext";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PageNotFound from "./pages/PageNotFound";
import Unapproved from "./pages/Unapproved";
import UpdateProfile from "./pages/UpdateProfile";
import Admin from "./pages/Admin";
import Homepage from "./pages/Homepage";
import About from "./pages/about_pages/About";
import faq from "./pages/about_pages/FAQs";
import Joining from "./pages/about_pages/Joining";
import Pricing from "./pages/about_pages/Pricing";
import TypicalDay from "./pages/about_pages/TypicalDay";
import WhatIsGliding from "./pages/about_pages/WhatIsGliding";
import AdminRoute from "./routes/AdminRoute";
import MemberRoute from "./routes/MemberRoute";
import UnapprovedRoute from "./routes/UnapprovedRoute";
import "./main.css"
import SuperAdmin from "./pages/SuperAdmin";
import { ApprovedProvider } from "./contexts/ApprovedContext";
import { DisplayedListsProvider } from "./contexts/DisplayedListsContext";
import { AdminProvider } from "./contexts/AdminContext";
import OnBoardingUpdated from "./pages/OnBoardingUpdated";

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
      fluid
    >
      <div>
        <Router>
          <AuthProvider>
            <ApprovedProvider>
              <DisplayedListsProvider>
                <AdminProvider>
                  <NavBar>
                    <Routes>
                      <Route
                        path="/"
                        Component={Homepage}
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <MemberRoute>
                            <Dashboard />
                          </MemberRoute>
                        }
                      />
                      <Route
                        path="/update-profile"
                        element={
                          <MemberRoute>
                            <UpdateProfile />
                          </MemberRoute>
                        }
                      />
                      <Route
                        path="/onBoardingUpdated"
                        element={
                          <MemberRoute>
                            <OnBoardingUpdated />
                          </MemberRoute>
                        }
                      />
                      <Route
                        path="/admin"
                        element={
                          <AdminRoute>
                            <Admin />
                          </AdminRoute>
                        }
                      />
                      <Route
                        path="/superAdmin"
                        element={
                          <AdminRoute>
                            <SuperAdmin />
                          </AdminRoute>
                        }
                      />
                      <Route
                        path="/unapproved"
                        element={
                          <UnapprovedRoute>
                            <Unapproved />
                          </UnapprovedRoute>
                        }
                      />
                      <Route path="/signup" Component={Signup} />
                      <Route path="/login" Component={Login} />
                      <Route path="/forgot-password" Component={ForgotPassword} />

                      <Route path="/about" Component={About} />
                      <Route path="/faq" Component={faq} />
                      <Route path="/what-is-gliding" Component={WhatIsGliding} />
                      <Route path="/joining" Component={Joining} />
                      <Route path="/typical-day" Component={TypicalDay} />
                      <Route path="/pricing" Component={Pricing} />
                      <Route path="*" Component={PageNotFound} />
                    </Routes>
                  </NavBar>
                </AdminProvider>
              </DisplayedListsProvider>
            </ApprovedProvider>
          </AuthProvider>
        </Router>
      </div>
    </Container>
  );
}

export default App;
