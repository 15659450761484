import { Row, Col, Alert } from "react-bootstrap";
import * as React from "react";
import StaticWrapper from "../../components/StaticWrapper";

export default function Joining() {
  return (
    <StaticWrapper title="Joining">
      <Row>
        <Col md={8}>
          <h3>Check you can join</h3>
          <p>
            Make sure you are a member of the{" "}
            <a href="https://www.susu.org/about-susu/membership/">
              Students' Union
            </a>
          </p>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <h3>Get a membership</h3>
          <p>
            Next, you will need to purchase a society "Full Membership" directly
            from SUSU. You can do this{" "}
            <a href="https://www.susu.org/groups/sugc#fees">here</a>.
          </p>
          <p>
            Unfortunately, we have a limit on our membership numbers for both
            safety reasons and to ensure all members have the chance to fly.
            Sometimes, the society becomes over-subscribed and we have to start
            a waiting list. We try to get through this as quickly as possible!
          </p>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <h3>Register here</h3>
          <p>
            Finally, you'll need to register for a flying account{" "}
            <a href="/signup">here</a>. This is how we organise all the flying!
            Make sure you add some availability in order to be selected!
          </p>
        </Col>
      </Row>

      <Alert variant="warning" className="mt-5">
        <h3>Having problems?</h3> Get in touch with us{" "}
        <a href="mailto:gliding@soton.ac.uk" className="alert-link">
          {" "}
          here
        </a>
      </Alert>
    </StaticWrapper>
  );
}
