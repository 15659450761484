import * as React from "react";
import { ColumnDef, ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { UserInfo } from "../contexts/ContextTypes";
import RemoveButton from "./RemoveButton";

export class AvailabilityColumnBuilder {
  columnHelper: ColumnHelper<UserInfo>;
  columns: ColumnDef<UserInfo, any>[];

  constructor() {
    this.columnHelper = createColumnHelper<UserInfo>();
    this.columns = [];
  }

  addAvailableDate() {
    this.columns.push(
      this.columnHelper.accessor(row =>
        row.dateRequested ? row.dateRequested.toDateString() : "", {
        header: "Available",
        cell: row => row.getValue(),
        meta: {
          textCenter: true
        }
      })
    )
  }

  addAvailabilityAddedOn() {
    this.columns.push(
      this.columnHelper.accessor(row =>
        row.dateCreated ? row.dateCreated.toDateString() : "", {
        header: "Availability added on",
        cell: row => row.getValue(),
        meta: {
          textCenter: true
        }
      }
      )
    )
  }

  addDeleteButton(removeAvailabilityHandler: (userInfo: UserInfo) => Promise<void>) {
    this.columns.push(
      this.columnHelper.display({
        id: "delButton",
        header: () => (
          <span className="w-100 d-inline-flex justify-content-center">
            Delete Availability
          </span>),
        cell: info =>
        <span className="w-100 d-inline-flex justify-content-center">
          <RemoveButton
            onClick={() => removeAvailabilityHandler(info.row.original)}
            disabled={info.row.original.final || false}
          /></span>,
        meta: {
          textCenter: true
        }
      })
    )
  }

  getColumns() {
    return this.columns
  }
}