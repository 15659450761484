import * as React from 'react';
import {  Col, Row } from "react-bootstrap";
import FlyingRateGeneration from '../components/admin/FlyingRateGeneration';
import GliderGeneration from '../components/admin/GliderGeneration';

export default function SuperAdmin() {
  return (
    <Row>
      <Col sm>
        <FlyingRateGeneration />
      </Col>
      <Col sm>
        <GliderGeneration />
      </Col>
    </Row>
  )
}
