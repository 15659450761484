import * as React from 'react';
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
// import UpdatePassword from '../components/updateProfile/UpdatePassword';
import ProfileInformation from '../components/updateProfile/ProfileInformation';

export default function UpdateProfile() {
  return (
    <CardGroup>
      <ProfileInformation /> 
      {/* <UpdatePassword /> */}
      <div className="w-100 text-center mt-2">
        <Link to="/dashboard">Cancel</Link>
      </div>
    </CardGroup>
  )
}
