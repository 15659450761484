import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function OnBoardingUpdated() {
  const { markOnBoardingAsUpdated } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    markOnBoardingAsUpdated().then(() => {
      console.log("here")
      navigate("/dashboard")
    });
  });

  return (
    <div>OnBoardingUpdating... Please hold caller we're having some Callum can't program difficulty's</div>
  )
}
