import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export class Glider {
  compNumber: string
  reg: string
  type: string
  rateName: string
  juniorRateName: string

  constructor(
    compNumber: string,
    reg: string,
    type: string,
    rateName: string,
    juniorRateName: string,
  ) {
    this.compNumber = compNumber;
    this.reg = reg;
    this.type = type;
    this.rateName = rateName;
    this.juniorRateName = juniorRateName;
  }
}

export const gliderConverter = {
  toFirestore: (glider: Glider) => {
    return {
      compNumber: glider.compNumber,
      reg: glider.reg,
      type: glider.type,
      rateName: glider.rateName,
      juniorRateName: glider.juniorRateName,
    }
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) => {
    return new Glider(
      snapshot.get("compNumber"),
      snapshot.get("reg"),
      snapshot.get("type"),
      snapshot.get("rateName"),
      snapshot.get("juniorRateName"),
    );
  }
};