import * as React from 'react';
import "./homepageStyle.css";

export default function Homepage() {
  return (
    <div className="heroImage d-flex justify-content-center align-items-center" style={{
        position: 'absolute',
        width: '100%', 
        height: '100%',
        top: 0,
        left: 0,
        zIndex: -100,
      }}>
        <div className="text-center" style={{color: 'white'}}>
          <h1>Southampton University Gliding Club</h1>
        </div>
      </div>
  )
}
