import * as React from 'react';
import { useState } from "react";
import { Button, Card, Form } from 'react-bootstrap';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table'
import { UserFlyingDay } from '../../models/UserFlyingDay';
import BootstrapTable from '../../table/BootstrapTable';
import { FlightsColumnBuilder } from '../../table/FlightsColumnBuilder';
import { useAdminContext } from '../../contexts/AdminContext';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';

export default function UnpublishedFlights() {
  const {publishFlyingDays} = useAdminContext();
  const {unpublishedUserFlyingDays} = useDisplayedListsContext();
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const columnBuilder = new FlightsColumnBuilder();
  columnBuilder.addDate(100000);
  columnBuilder.addP1P2();
  columnBuilder.addLaunches();
  columnBuilder.addFlightTime();
  columnBuilder.addCost();

  const table = useReactTable({
    data: unpublishedUserFlyingDays,
    columns: columnBuilder.getColumns(),
    state: {
      expanded,
    },
    getCoreRowModel: getCoreRowModel<UserFlyingDay>(),
    getPaginationRowModel: getPaginationRowModel(),
    enableMultiRowSelection: true,
    enableSubRowSelection: true,
    enableGrouping: true,
    getRowCanExpand: (row) => row.original instanceof UserFlyingDay,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel<UserFlyingDay>(),
    getSubRows: row => (row instanceof UserFlyingDay) ? row.flights : []
  })

  function publishDays(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const flyingDays: Array<UserFlyingDay> = [];
    table.getSelectedRowModel().rows.forEach(row => {
      if (row.original instanceof UserFlyingDay)
        flyingDays.push(row.original) 
    })

    publishFlyingDays(flyingDays);
  }

  return (
    <Card style={{ minWidth: "44em" }}>
      <Card.Header>
        <h2 className='text-center mb-4'>Unpublished Flights</h2>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center row">
        {/* https://react-bootstrap-table.github.io/react-bootstrap-table2/ */}
        <BootstrapTable table={table}>
          <Form className='row' onSubmit={publishDays}>
            <Button
              type='submit'
              disabled={table.getSelectedRowModel().rows.length === 0}
            >Publish ({table.getSelectedRowModel().rows.length}) selected Day(s)</Button>
          </Form>
        </BootstrapTable>
      </Card.Body>
      <Card.Footer>

      </Card.Footer>
    </Card>
  )
}
