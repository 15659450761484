import * as React from 'react'
import { Button, Card, Form } from 'react-bootstrap';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table'
import BootstrapTable from '../../table/BootstrapTable';
import { Payment } from '../../models/Payment';
import { PaymentsColumnBuilder } from '../../table/PaymentsColumnBuilder';
import { useAdminContext } from '../../contexts/AdminContext';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';

export default function PaymentApproval() {
  const {approvePayments} = useAdminContext();
  const {payments} = useDisplayedListsContext();

  const columnBuilder = new PaymentsColumnBuilder();
  columnBuilder.addPayee(); 
  columnBuilder.addDate();
  columnBuilder.addAmount();

  const table = useReactTable({
    data: payments,
    columns: columnBuilder.getColumns(),
    getCoreRowModel: getCoreRowModel<Payment>(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  function approveSelectedPayments(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    
    approvePayments(table.getSelectedRowModel().rows.map(
      row => row.original
    ))

    table.resetRowSelection();
  }
  
  return (
    <Card style={{ minWidth: "48em" }}>
      <Card.Header>
        <h2 className='text-center mb-4'>New Payments</h2>
      </Card.Header>
      <Card.Body>
        <BootstrapTable table={table}>
          <Form className='row' onSubmit={approveSelectedPayments}>
            <Button
              className='col'
              type='submit'
              disabled={table.getSelectedRowModel().rows.length === 0}
            >Approve ({table.getSelectedRowModel().rows.length}) selected payments(s)</Button>
          </Form>
        </BootstrapTable>
      </Card.Body>
      <Card.Footer>
        The above shows any payments users claim to have made
      </Card.Footer>
    </Card>
  )
}