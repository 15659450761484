import * as React from 'react';
import { useState } from 'react'
import { Card, Button, Alert, Form, InputGroup } from 'react-bootstrap'
import { useApprovedContext } from '../../contexts/ApprovedContext';
import 'react-day-picker/dist/style.css';
import { useAuth } from '../../contexts/AuthContext';

export default function Availability() {
  const [error, setError] = useState("");
  const { setDriver, setMaxPassengerCount } = useApprovedContext();
  const { currentUserInfo, updateCurrentUserInfo, setUserDetails, currentUser, markDetailsAsSet } = useAuth();

  const firstNameRef = React.useRef<HTMLInputElement>(null);
  const lastNameRef = React.useRef<HTMLInputElement>(null);
  const dobRef = React.useRef<HTMLInputElement>(null);

  const [isDriver, setIsDriver] = React.useState<boolean>(Boolean(currentUserInfo?.isDriver));

  async function handleSubmission(e : React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setError("")
    if (!currentUser) return setError("Not logged in - please try signing in again");
    if (!firstNameRef.current) return setError("Error - firstname not set - Blame Callum");
    if (!lastNameRef.current) return setError("Error - lastname not set - Blame Callum");
    if (!dobRef.current) return setError("Error - dob not set - Blame Callum");
    if (!dobRef.current.valueAsDate) return setError("Unable to update - dob not set");

    setDriver(isDriver);
    await setUserDetails(
      firstNameRef.current.value,
      lastNameRef.current.value,
      dobRef.current.valueAsDate ? dobRef.current.valueAsDate : new Date(),
      currentUser
    )

    await markDetailsAsSet(currentUser);
    updateCurrentUserInfo();
  }

  async function passengerCountChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const newCount = parseInt(event.currentTarget.value);
    if (newCount === undefined) return;

    await setMaxPassengerCount(newCount);
    updateCurrentUserInfo();
  }

  return (
    <Card>
      <Card.Header>
        <h2 className='text-center mb-4'>Set Account Details</h2>
        Please Confim all the below details are correct and submit to continue.
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmission}>
          <InputGroup className='mb-3'>
            <InputGroup.Text>First and last name:</InputGroup.Text>
            <Form.Control 
              placeholder='First Name'
              ref={firstNameRef}
              defaultValue={currentUserInfo?.firstName}
            />
            <Form.Control 
              placeholder='Last Name'
              ref={lastNameRef}
              defaultValue={currentUserInfo?.lastName}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text>DOB:</InputGroup.Text>
            <Form.Control
              type='date'
              ref={dobRef}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Checkbox
              checked={isDriver}
              onChange={() => setIsDriver(!isDriver)}
            />
            <InputGroup.Text
              onClick={() => setIsDriver(!isDriver)}
            >
              Is Driver?
            </InputGroup.Text>
            <InputGroup.Text hidden={!isDriver}>
              Passenger capacity (excl driver)
            </InputGroup.Text>
            <Form.Control
              hidden={!isDriver}
              type='number'
              value={currentUserInfo?.passengerCount}
              min={0}
              onChange={passengerCountChanged}
            />
          </InputGroup>
          <Button className='w-100 mt-3' type="submit">Set Account Details</Button> </Form> </Card.Body>
    </Card>
  );
}
    
