import * as React from 'react';
import { useState } from 'react';
import { Alert, Button, Card } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Unapproved() {
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <h2 className='text-center mb-4'>You are not yet approved</h2>
          <p>SUGC "dot" net requires new users to be manually approved, please bare with us.</p>
          <div className="w-100 text-center mt-2">
            <Button onClick={handleLogout} className='btn-link m-auto'>Log Out</Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
