import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { SetStateAction } from "react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);

export const getFCMToken = (setToken: {
  (value: SetStateAction<string>): void;
  (arg0: string): void;
}) => {
  try {
    isSupported().then((isSupported) => {
      if (isSupported) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification User Permission Granted.");
            return getToken(messaging, {
              vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
            })
              .then((currentToken) => {
                if (currentToken) {
                  console.log("Client Token: ", currentToken);
                  setToken(currentToken);
                } else {
                  console.log("Failed to generate the app registration token.");
                }
              })
              .catch((err) => {
                console.log(
                  "An error occurred when requesting to receive the token.",
                  err
                );
              });
          } else {
            console.log("User Permission Denied.");
          }
        });
      } else {
        console.log("Notifications not supported on this platform");
      }
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const onForegroundMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default app;
