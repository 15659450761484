import * as React from "react";
import { useContext } from "react";
import { db } from "../firebase";
import {
  collection,
  Timestamp,
  serverTimestamp,
  doc,
  writeBatch,
  updateDoc,
  getDocs,
  addDoc,
  setDoc,
  getDoc
} from "firebase/firestore";

import { getFlights, getUserList } from "./Utils";
import { AvailabilityData, ProviderProps, SelectableUser, UserInfo, UserState } from "./ContextTypes";
import { ApprovedContextModel } from "./ApprovedContextInterface";
import { useAuth } from "./AuthContext";
import { Status, UserFlyingDay, userFlyingDayConverter } from "../models/UserFlyingDay";
import { Payment, PaymentStatus, paymentsConverter } from "../models/Payment";

const DBContext = React.createContext<ApprovedContextModel>({} as ApprovedContextModel);

export function useApprovedContext() {
  return useContext(DBContext);
}

//TODO: Remove these
const userAvailabilityReference = (date: Date, uid: string) =>
  doc(db, "users", uid, "availability", Timestamp.fromDate(date).valueOf());

const signedUpListReference = (date: Date) =>
  collection(
    db,
    "availability",
    Timestamp.fromDate(date).valueOf(),
    "signedUp"
  );

const flyingListReference = (date: Date) =>
  collection(
    db,
    "availability",
    Timestamp.fromDate(date).valueOf(),
    "flyingList"
  );
  
const userDocumentReference = (uid: string) => doc(db, "users", uid);

export function ApprovedProvider({ children }: ProviderProps): JSX.Element {
  const {currentUser, currentUserInfo} = useAuth()

  async function addAvailability(date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    const dateTimestamp = Timestamp.fromDate(date);

    const data: AvailabilityData = {
      dateRequested: dateTimestamp,
      dateCreated: serverTimestamp(),
      userFCMTokens: currentUserInfo.fcmTokens ? currentUserInfo.fcmTokens : []
    };

    const batch = writeBatch(db);

    batch.set(userAvailabilityReference(date, currentUser.uid), data);
    if (currentUserInfo) {
      data["firstName"] = currentUserInfo.firstName;
      data["lastName"] = currentUserInfo.lastName;
    }
    
    if (currentUserInfo.isDriver) {
      data["isDriver"] = true;
      data["passengerCount"] = currentUserInfo.passengerCount;
    }

    batch.set(doc(signedUpListReference(date), currentUser.uid), data);
    await batch.commit();

    if (
      (await getDoc(doc(
        db,
        "availability",
        Timestamp.fromDate(date).valueOf()
      ))).exists())
      return;

    setDoc(doc(
        db,
        "availability",
        Timestamp.fromDate(date).valueOf()
      ), {
        final: false
      }, {
        merge: false
      });
  }

  function removeAvailability(date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    const references = [
      userAvailabilityReference(date, currentUser.uid),
      doc(signedUpListReference(date), currentUser.uid),
      doc(flyingListReference(date), currentUser.uid),
    ];

    const batch = writeBatch(db);

    references.forEach((reference) => {
      batch.delete(reference);
    });

    return batch.commit();
  }
  
  async function getUserAvailability() {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    const availabilityCollectionReference = collection(
      db,
      "users",
      currentUser.uid,
      "availability"
    );

    return (await getUserList(availabilityCollectionReference))
      .sort((availabilityA: UserInfo, availabilityB: UserInfo) => 
        (!availabilityA.dateRequested) ? 0 :
        (!availabilityB.dateRequested) ? 0 :
        availabilityA.dateRequested.valueOf() - availabilityB.dateRequested.valueOf())
  }
  
  async function setPhoneNumber(phoneNumber: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");
    
    await setDoc(userDocumentReference(currentUser.uid), {
      phoneNumber: phoneNumber,
    }, {
      merge: true,
    });
  }

  async function setMaxPassengerCount(passengerCount: number) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");
    //if (!currentUserInfo.isDriver) throw new Error("User is not marked as a driver");

    return await updateDoc(userDocumentReference(currentUser.uid), {
      passengerCount: passengerCount,
    });
  }
  
  async function setDriver(value: boolean) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    await updateDoc(userDocumentReference(currentUser.uid), { driver: value });
  }

  async function addUserFcmToken(token: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");
    
    console.log("adding token to user db");
    // Get current FCM tokens
    const currentTokens = currentUserInfo.fcmTokens || [];
    // If token does not exist in array, add it
    if (!currentTokens.includes(token)) {
      const newTokens = [...currentTokens, token];
      return await updateDoc(userDocumentReference(currentUser.uid), {
        fcmTokens: newTokens,
      });
    } else {
      return Promise.resolve();
    }
  }
  
  async function getUsersFlyingDays(uid: string) : Promise<Array<UserFlyingDay>> {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    return (await Promise.all((await getDocs(
      collection(db, "users", uid, "flyingDays")
        .withConverter(userFlyingDayConverter)
    )).docs.map(
      async flyingDay => await getFlights(
        flyingDay.data(),
        collection(db, "users", uid, "flyingDays")        
        )
    )))
      .sort((Day1, Day2) => Day1.date.valueOf() - Day2.date.valueOf())
      .sort((Day1, Day2) => Day1.status - Day2.status)
  }

  async function markDaysAsPaid(flyingDays: Array<UserFlyingDay>, notes?: string): Promise<void> {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    await Promise.all(
      flyingDays.flatMap(
        day => [
          updateDoc(
            doc(db, "flyingDays", day.getDayReference()),
            {"status": Status.ClaimedPaid}
          ),
          updateDoc(
            doc(db, "users", day.user.uid, "flyingDays", day.getDayReference()),
            {"status": Status.ClaimedPaid}
          ),
        ]
      )
    )
    
    let payment = new Payment(
      "ForTheLoveOfGodDon'tShowUpInFirebase",
      flyingDays.reduce((currentCost, day) => currentCost + day.cost, 0),
      new Date(),
      flyingDays[0].user,
      PaymentStatus.created,
      flyingDays.map(day => day.getDayReference()),
      notes
    )

    const paymentRef = await addDoc(
      collection(db, "payments")
      .withConverter(paymentsConverter),
      payment
    )

    await setDoc(
      doc(
        db,
        "users",
        flyingDays[0].user.uid,
        "payments",
        paymentRef.id
      )
      .withConverter(paymentsConverter),
      payment
    )
  }

  async function getUsersPayments(uid: string): Promise<Array<Payment>> {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    return (await getDocs(
      collection(
        db,
        "users",
        uid,
        "payments"
      ).withConverter(paymentsConverter)
    )).docs.map(doc => doc.data())
      .sort((Day1, Day2) => Day1.datePaid.valueOf() - Day2.datePaid.valueOf())
      .sort((Day1, Day2) => Day1.status - Day2.status)
  }

  async function getMiscPayment(uid: string) {
    const ammount = (await getDoc(doc(db, "miscInvoices", uid))).get("ammount") 
    if (!ammount) return 42069; //This is a justified error code as if a user sees it they will not pay it
    return ammount;
  }

  async function markMiscPaymentAsMade(uid: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isApproved) throw new Error("User is not approved");

    const ammount = (await getDoc(doc(db, "miscInvoices", uid))).get("ammount");
    if (!ammount) throw new Error("Well that didn't work, tell callum he can't program")
    
    const payee:SelectableUser = {
      uid: uid,
      firstName: currentUserInfo.firstName,
      lastName: currentUserInfo.lastName,
      age: currentUserInfo.dob
        ?
        Math.abs((new Date(Date.now() - currentUserInfo.dob.getTime())).getUTCFullYear() - 1970)
        :
        50
      }
      
    let payment = new Payment(
      "ForTheLoveOfGodDon'tShowUpInFirebase",
      ammount, 
      new Date(),
      payee,
      PaymentStatus.created,
      [],
      "Misc Payment from Acc creation"
    )

    const paymentRef = await addDoc(
      collection(db, "payments")
      .withConverter(paymentsConverter),
      payment
    )

    const batch = writeBatch(db);

    batch.set(
      doc(
        db,
        "users",
        uid,
        "payments",
        paymentRef.id
      )
      .withConverter(paymentsConverter),
      payment
    )
    batch.update(
      doc(
        db,
        "miscInvoices",
        uid,
      ), {
        status: PaymentStatus.created
      }
    )
    batch.update(
      doc(
        db,
        "users",
        uid
      ), {
        userState: UserState.approved,
      }
    )
    batch.commit();
  }

  const value = {
    addAvailability,
    removeAvailability,
    getUserAvailability,

    setPhoneNumber,
    setMaxPassengerCount,
    setDriver,
    addUserFcmToken,

    getUsersFlyingDays,
    markDaysAsPaid,

    getUsersPayments,

    getMiscPayment,
    markMiscPaymentAsMade,
  };

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>;
}
